import React from "react";
import img from "../../media/images/Full-Body-Hair-Removal-For-Male-Salon.png"
import img1 from "../../media/images/delhi.png"
import img2 from "../../media/images/noida.png"
import img3 from "../../media/images/greater-noida.png"
import img4 from "../../media/images/gurgaon.png"
// import img4 from "../../media/images/gurgaon (1).png"
import img5 from "../../media/images/ghaziabad.png"
import img6 from "../../media/images/faridabad.png"
import img7 from "../../media/images/bangalore.png"
import img8 from "../../media/images/chandigarh.png"
import { Link } from "react-router-dom";

const LocationComponent = () => {
  return (
    <div>
      <div className="location">
        <div>
          <h2 className="location-heading">GLAZMA MEN'S SALON AT HOME SERVICED AREA</h2>
          <div className="location-icon"><img title="location icon" src={img} alt="icon" /></div>
          <div className="location-col">

            <Link title="Best Hair Removal For Bikini Area" to="/salon-at-home/delhi" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img1} alt="location-icon" /></div>
              <div className="location-list-text">Delhi</div>
            </Link>
            

            <Link title="Cheap Waxing Near Me" to="/salon-at-home/noida" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img2} alt="location-icon" /></div>
              <div className="location-list-text">Noida</div>
            </Link>
            

            <Link title="Best Way To Get Rid Of Pubic Hair" to="/salon-at-home/greater-noida" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img3} alt="location-icon" /></div>
              <div className="location-list-text">Greater Noida</div>
            </Link>
            

            <Link title="Brazilian Sugaring Near Me" to="/salon-at-home/gurgaon" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img4} alt="location-icon" /></div>
              <div className="location-list-text">Gurgaon</div>
            </Link>
            

            <Link title="Underarm Waxing Near Me" to="/salon-at-home/ghaziabad" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img5} alt="location-icon" /></div>
              <div className="location-list-text">Ghaziabad</div>
            </Link>
            

            <Link title="Bikini Area Hair Removal" to="/salon-at-home/Faridabad" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img6} alt="location-icon" /></div>
              <div className="location-list-text">Faridabad</div>
            </Link>
            

            <Link title="Hollywood Wax Near Me" to="/salon-at-home/chandigarh" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img8} alt="location-icon" /></div>
              <div className="location-list-text">Chandigarh</div>
            </Link>
            

            <Link title="Wax Spa Near Me" to="/salon-at-home/bengaluru" className="location-list">
              <div className="location-list-icon"><img title="location icon" src={img7} alt="location-icon" /></div>
              <div className="location-list-text">Bengaluru</div>
            </Link>
            
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationComponent;
